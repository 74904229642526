/* App.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0 20px;
  }
  
  .logo img {
    max-height: 64px;
  }
  
  .hero-section {
    text-align: center;
    position: relative;
  }
  
  .hero-section img {
    width: 100%;
    height: auto;
  }
  
  .hero-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .hero-text h1, .hero-text h2 {
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
  }
  
  .hero-text h1 {
    font-size: 48px;
  }
  
  .hero-text h2 {
    font-size: 24px;
  }
  
  .search-bar-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding: 0 50px;
  }
  
  .search-bar {
    width: 100%;
    max-width: 800px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .search-bar .ant-input-group-compact {
    display: flex;
    flex-wrap: wrap;
  }
  
  .ant-carousel .slick-slide {
    text-align: center;
    height: 400px;
    line-height: 400px;
    background: #364d79;
    overflow: hidden;
  }
  
  .ant-carousel .slick-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .site-layout-content {
    padding: 24px;
    background: #fff;
    min-height: 380px;
    margin-top: 20px;
  }
  
  .site-layout-content h2 {
    margin-bottom: 20px;
  }

  .footer {
    background-color: #f0f2f5;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer h3 {
    margin-bottom: 10px;
    margin-top: 100px;
  }
  
  .footer p {
    margin: 0;
  }
  